import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import PriceRealized from './PriceRealized';
import { getAuctionEventDates, pastAuction } from 'shared/helpers/auctions';
import Spinner from 'components/Spinner';
import SEO from 'components/seo';

export default ({ data }) => {
  const { contentfulWebPageAuction } = data;
  const getTotalSalePrice = auction => {
    return (
      auction.lot &&
      auction.lot.length > 0 &&
      auction.lot.map(lot => lot.salePrice).reduce((acc, val) => acc + val)
    );
  };

  useEffect(() => {
    // If auction has not passed yet, redirect to homepage
    if (
      !pastAuction(contentfulWebPageAuction.auction) ||
      !contentfulWebPageAuction.auction?.lot?.length
    ) {
      navigate('/');
    }
  }, [contentfulWebPageAuction.auction]);

  const auctionEvents = getAuctionEventDates(contentfulWebPageAuction.auction);
  const totalSalePrice = getTotalSalePrice(contentfulWebPageAuction.auction);

  if (
    !pastAuction(contentfulWebPageAuction.auction) ||
    !contentfulWebPageAuction.auction?.lot?.length
  ) {
    return <Spinner />;
  }

  return (
    <>
      <SEO title={`${contentfulWebPageAuction.title} Prices Realized`} />
      <PriceRealized
        title={contentfulWebPageAuction.auction.name}
        image={contentfulWebPageAuction.featureImage}
        auctionEvents={auctionEvents}
        totalSalePrice={totalSalePrice}
        lots={contentfulWebPageAuction.auction.lot.filter(lot => lot.salePrice)}
        sellThroughRate={contentfulWebPageAuction.auction.sellThroughRate}
        videoPromo={contentfulWebPageAuction.auction.priceRealizedVideoPromo}
        priceRealizedFile={
          contentfulWebPageAuction.auction.priceRealizedFile?.file?.url
        }
        location={contentfulWebPageAuction.auction.location}
      />
    </>
  );
};

export const query = graphql`
  query AuctionWebPage($id: String) {
    contentfulWebPageAuction(contentful_id: { eq: $id }) {
      title
      featureImage {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      auction {
        name
        sellThroughRate
        priceRealizedFile {
          file {
            url
          }
        }
        priceRealizedVideoPromo
        subEvents {
          __typename
          ... on ContentfulSubEventAuction {
            startDate
            endDate
          }
        }
        location {
          address {
            ... on ContentfulAddress {
              id
              addressCountry
            }
            ... on ContentfulAddressFreeform {
              id
              addressCountry
            }
          }
        }
        lot {
          slug
          salePrice
          privateSalesPrice
          lotNumber
          item {
            __typename
            ... on ContentfulAutomobilia {
              title
              id
              salesForceId
              images {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }

            ... on ContentfulVehicle {
              title
              modelYear
              id
              make {
                name
              }
              model
              salesForceId
              images {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
