import moment from 'moment';
import qs from 'qs';
import {
  OFFLINE_AUCTION,
  ONLINE_ONLY_AUCTION,
  MIXED_AUCTION,
} from 'utils/auctionTypes';

export const getFormatedDate = (list, dateKey, operation) => {
  switch (operation) {
    case 'greaterThan':
      return (
        list?.length > 0 &&
        list.reduce(function (a, b) {
          return new Date(a[dateKey]) > new Date(b[dateKey]) ? a : b;
        })
      );
    case 'lessThan':
      return (
        list?.length > 0 &&
        list.reduce(function (a, b) {
          return new Date(a[dateKey]) < new Date(b[dateKey]) ? a : b;
        })
      );
    default:
      return [];
  }
};

export const getEventDateAverage = subEvents => {
  const startDate = new Date(
    getFormatedDate(subEvents, 'startDate', 'lessThan').startDate
  );

  const endDate = new Date(
    getFormatedDate(subEvents, 'endDate', 'greaterThan').endDate
  );
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let result = '';
  if (startDate.getDate() === endDate.getDate()) {
    result = `${startDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}`;
  } else {
    result = `
    ${startDate.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
    })}
    ${diffDays > 1 ? '-' : '&'}
    ${endDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })}
    `
      .split(' ')
      .filter(function (currentItem, i, allItems) {
        return i === allItems.indexOf(currentItem);
      })
      .join(' ');
  }
  return result;
};

export const getEventTimeAverage = subEvents => {
  let result = `
  ${moment(
    getFormatedDate(subEvents, 'startDate', 'lessThan').startDate
  ).format('hh:mm A')}
  -
  ${moment(getFormatedDate(subEvents, 'endDate', 'greaterThan').endDate).format(
    'hh:mm A'
  )}
  `;
  return result;
};

export const pastAuction = auction => {
  const auctionEvents = getAuctionEvents(auction);

  let isPastAuction = true;
  auctionEvents.forEach(event => {
    if (new Date(event.endDate) >= new Date()) isPastAuction = false;
  });
  return isPastAuction;
};

export const getAuctionEventDates = auction => {
  const auctionEvents = getAuctionEvents(auction).sort(
    (d1, d2) => Date.parse(d1.startDate) - Date.parse(d2.startDate)
  );
  return auctionEvents;
};

export const getAuctionEventDatesFlat = auction => {
  const auctionEvents = getAuctionEvents(auction).sort(
    (d1, d2) => Date.parse(d1.startDate) - Date.parse(d2.startDate)
  );
  return auctionEvents.map(event => [event.startDate, event.endDate]).flat();
};

function getAuctionEvents(auction) {
  return (
    auction?.subEvents?.filter(
      event => event.__typename === 'ContentfulSubEventAuction'
    ) || []
  );
}

export const getCataloguePreFilteredByAuction = (auction, auctionYear, upcoming = false) => {
  let params = '';
  const sortBy = upcoming ? 'ENDING_SOONEST' : 'DEFAULT'
  switch (auction.eventAttendanceMode) {
    case MIXED_AUCTION:
    case OFFLINE_AUCTION:
      params = {
        filtersInput: {
          venue: [auction.location.name],
          auctionYear: [auctionYear],
        },
        sortBy: sortBy,
      };
      break;
    case ONLINE_ONLY_AUCTION:
      params = {
        filtersInput: {
          auctionType: ['Online Only'],
          auctionYear: [auctionYear],
        },
        sortBy: sortBy,
      };
      break;
    default:
      params = '';
  }

  return `/vehicles/?${qs.stringify(params)}`;
};
