import moment from 'moment';

export const getOneLineDate = dates => {
  const datesByMonth = dates.reduce((accumulator, currentValue) => {
    const currentDate = moment(currentValue);
    const month = currentDate.format('MMMM');
    const monthArray = accumulator[month] || [];
    monthArray.push(currentDate.format('MM-DD-YYYY'));
    return { ...accumulator, [month]: monthArray };
  }, {});

  const datesArray = Object.keys(datesByMonth).map(month => ({
    month,
    dates: [...new Set(datesByMonth[month])],
  }));

  const oneLine = datesArray
    .map(date => {
      const days = date.dates
        .map(date => moment(date).format('D'))
        .reduce((accumulator, currentValue, index) => {
          const separator = index === date.dates.length - 1 ? ' & ' : ', ';
          return `${accumulator}${separator}${currentValue}`;
        });

      return `${date.month} ${days}, ${moment(date.dates[0]).format('YYYY')}`;
    })
    .reduce((accumulator, currentValue) => `${accumulator}. ${currentValue}`);

  return oneLine;
};
