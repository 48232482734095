import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Vehicles from 'features/pricesRealized/vehicles';
import YouTubePlayer from 'components/YoutubePlayer';
import HeroSection from 'components/HeroSection';
import styles from './PricesRealized.module.scss';
import { getOneLineDate } from 'utils/date';
import { getCurrencyCode } from 'shared/helpers/vehicles';

const PricesRealized = ({
  title,
  image,
  auctionEvents,
  totalSalePrice,
  lots,
  sellThroughRate,
  videoPromo,
  priceRealizedFile,
  location,
}) => {
  const oneLineAuctionEvents = getOneLineDate(
    auctionEvents.map(event => [event.startDate, event.endDate]).flat()
  );

  const getSellThroughRateText = () =>
    sellThroughRate ? `${sellThroughRate * 100}% Sell-Through Rate` : '';

  const totalSalePriceFormatted = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(totalSalePrice);

  const address = location?.address;
  const countryCode = address?.addressCountry;
  const currencyCode = countryCode ? getCurrencyCode(countryCode) : 'USD';

  const getButtons = () => {
    return priceRealizedFile
      ? [
          {
            title: 'Download PDF',
            onClick: () => {
              const windowRef = typeof window !== `undefined` ? window : null;
              windowRef.open(priceRealizedFile);
            },
          },
        ]
      : [];
  };

  return (
    <Layout transparentHeader={true} paddingTop={false}>
      <SEO title="Prices Realized" />
      <div className={styles.videoWrapper}>
        <HeroSection
          key={`pricesRealizedHeroSection`}
          description={`<p class="titleLg">${oneLineAuctionEvents}</p>`}
          image={image}
          title={title}
          leftButtons={getButtons()}
          subTitles={[
            { title: 'Prices Realized' },
            { title: `Total Sold: ${totalSalePriceFormatted}`, bold: true },
            { title: getSellThroughRateText(), bold: true },
          ]}
          titleWithoutMargin={true}
        />

        <div className={styles.videoSection}>
          <YouTubePlayer url={videoPromo} />
        </div>
      </div>

      <Vehicles
        lots={lots || []}
        auctionName={title}
        auctionYear={new Date(auctionEvents[0].startDate).getFullYear()}
        currencyCode={currencyCode}
      />
    </Layout>
  );
};

export default PricesRealized;
