import React, { useState, useEffect } from 'react';
import { SORTING_OPTIONS } from './data';
import VehicleCard from 'components/VehicleCard';
import Filters from '../filters';
import styles from './vehicles.module.scss';
import {
  getContentfulItemTitle,
  getPriceFormatted,
} from 'shared/helpers/vehicles';
import { generateFluidImage } from 'utils/image';

const Vehicles = ({ lots = [], auctionYear, auctionName, currencyCode }) => {
  const [sortedLots, setSortedLots] = useState(lots);
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false);
  const sortToggle = () => setSortPopoverOpen(!sortPopoverOpen);
  const [selectedSort, setSelectedSort] = useState(SORTING_OPTIONS[0]);
  const [vehicleCardType, setVehicleCardType] = useState('grid');

  // Sort the lots on changing the sort option
  useEffect(() => {
    setSortedLots(sortedLots => [].concat(sortedLots).sort(selectedSort.sort));
  }, [selectedSort]);

  return (
    <>
      <div className={styles.filterWrapper}>
        <Filters
          vehicleCardType={vehicleCardType}
          setVehicleCardType={type => setVehicleCardType(type)}
          sort={selectedSort}
          sortToggle={sortToggle}
          setSort={setSelectedSort}
          sortPopoverOpen={sortPopoverOpen}
        />
        <p>
          Unsold lots are not shown. Gooding &amp; Company is not responsible
          for typographical errors or omissions.
        </p>
      </div>
      <div
        className={`${styles.vehiclesContainer} ${
          vehicleCardType === 'list' ? styles.vehiclesListContainer : ''
        }`}
      >
        <div
          className={`${styles.vehiclesWrapper} ${
            vehicleCardType === 'grid' ? styles.gridWrapper : styles.listWrapper
          }`}
        >
          {sortedLots.map((lot, index) => {
            const title = getContentfulItemTitle(lot.item);
            return (
              <VehicleCard
                key={`vehicleCard_${index}`}
                fluidImages={lot.item.images?.slice(0, 3).map(image => {
                  const width = image.file?.details?.image?.width;
                  const height = image.file?.details?.image?.height;
                  return {
                    fluid: generateFluidImage(
                      350,
                      image.file.url,
                      90,
                      width,
                      height
                    ),
                  };
                })}
                lotNumber={lot.lotNumber}
                price={`SOLD ${
                  lot.privateSalesPrice
                    ? ''
                    : `| ${getPriceFormatted(lot.salePrice, currencyCode)}`
                }`}
                slug={lot.slug}
                makeModel={title}
                auction={`${auctionYear} | ${auctionName}`}
                cardType={vehicleCardType}
                showDotsOnHover={true}
                salesForceId={lot?.item?.salesForceId}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Vehicles;
