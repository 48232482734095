import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledPopover } from 'reactstrap';
import { nodePropType } from 'shared/propTypes';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styles from './popover.module.scss';

const Popover = ({
  label,
  children,
  id,
  hideArrow,
  showCaretDown,
  active,
  popoverOpen,
  toggle,
}) => (
  <Fragment>
    <Button
      type={`${styles.appPopoverBtn} ${
        popoverOpen || active ? 'app-primary-button' : 'app-default-button'
      }`}
      id={id}
    >
      {label}
      {showCaretDown && (
        <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
      )}
    </Button>
    <UncontrolledPopover
      trigger="legacy"
      fade={false}
      hideArrow={hideArrow}
      placement="bottom"
      target={id}
      boundariesElement="window"
      isOpen={popoverOpen}
      toggle={toggle}
      className={styles.appPopoverWrapper}
    >
      {children}
    </UncontrolledPopover>
  </Fragment>
);

Popover.propTypes = {
  children: nodePropType.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  hideArrow: PropTypes.bool,
  showCaretDown: PropTypes.bool,
  active: PropTypes.bool,
  popoverOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

Popover.defaultProps = {
  hideArrow: true,
  active: false,
  showCaretDown: false,
};

export default Popover;
